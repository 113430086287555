@import '~jquery-nice-select/scss/nice-select.scss';
@import './flexboxgrid.css';
@import 'tippy.js/dist/tippy.css';
@import 'tippy.js/animations/shift-toward.css';
@import 'tippy.js/themes/light-border.css';


@tailwind base;

@tailwind components;

@tailwind utilities;


body {
  font-family: -apple-system, Roboto, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #191919;
  font-weight: 900;
  padding: 0px;
  margin: 0px;
}

h2 {
  text-align: left;
  font-weight: 400;
  letter-spacing: .5px;
  margin-bottom: 0;
}

p {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: left;
  margin-top: 0;
}

div.container-fluid {
  padding-left: 0;
  padding-right: 0;

}

div.row ,section.row {
  margin-left: 0;
  margin-right: 0;
}

header {
  background-image: url(../images/home-background.webp);
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 60%;
  height: 100vh;
  min-height: 610px;
}

.main-bg-img {
  background-repeat: no-repeat;
  background-size: 100%;
  padding-bottom: 50px;
  position: relative;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 0px;
}

.site-logo img {
  width: 140px;
  margin: 20px;
}

.hero{
  /* height: calc(100vh - 85px); */
  padding-top : 120px;
}

.site-topic h1 {
  font-size: 43px;
  text-align: center;
  font-weight: 900;
  margin-top: 0px;
  margin-bottom: 0px;
}

.site-topic p {
  text-align: center;
  margin-bottom: 0;
}

.nice-select {
  margin-top: 20px;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  border: none;
  width: 349px;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  border: none;
  box-sizing: border-box;
  margin-top: 20px;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  height: 50px;
  line-height: 50px;
  outline: none;
  padding-left: 18px;
  padding-right: 18px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
}

.nice-select .option {
  cursor: pointer;
  width: 300px;
  font-weight: 400;
  line-height: 40px;
  color: black;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0px;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;

}

.nice-select:after {
  border-bottom: 2px solid #999;
  border-right: 2px solid #999;
  content: '';
  display: block;
  height: 5px;
  margin-top: -4px;
  pointer-events: none;
  position: absolute;
  right: 20px;
  top: 50%;
  transform-origin: 66% 66%;
  transform: rotate(45deg);
  transition: all 0.15s ease-in-out;
  width: 5px;
}

.nice-select.open:after {
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.nice-select.open .list {
  width: 100%;
  opacity: 1;
  pointer-events: auto;
  transform: scale(1) translateY(0);
}

.nice-select.disabled {
  border-color: #ededed;
  color: #999;
  pointer-events: none;
}

.nice-select.disabled:after {
  border-color: #cccccc;
}

.nice-select.wide {
  width: 350px;
}

.nice-select.wide .list {
  left: 0 !important;
  right: 0 !important;
}

.nice-select.right {
  float: right;
}

.nice-select.right .list {
  left: auto;
  right: 0;
}

.nice-select.small {
  font-size: 15px;
  height: 36px;
  line-height: 34px;
}

.nice-select.small:after {
  height: 4px;
  width: 4px;
}

.nice-select.small .option {
  line-height: 34px;
  min-height: 34px;
}

.nice-select .list {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  left: 0;
  transform-origin: 50% 0;
  transform: scale(0.75) translateY(-21px);
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.nice-select .list:hover .option:not(:hover) {
  background-color: transparent !important;
}

.nice-select .option {
  cursor: pointer;
  font-weight: 400;
  list-style: none;
  outline: none;
  padding: 5px 25px;
}


.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
  background-color: #f6f6f6;
}

.nice-select .option.selected {
  font-weight: bold;
}

.nice-select .option.disabled {
  background-color: transparent;
  color: #999;
  cursor: default;
}

.no-csspointerevents .nice-select .list {
  display: none;
}

.no-csspointerevents .nice-select.open .list {
  display: block;
}


.chat-button {
  position : relative;
  cursor: pointer;
  outline: none;
  margin-top: 1.5em;
  padding: 15px 25px;
  font-size: 15px;
  border-radius: 6px;
  background: #0e54ff;
  border: none;
  color: #fff;
  font-weight: 300;
  transition: box-shadow .3s ease-in;
}

button.chat-button.chat-button-outline {
  background: transparent;
  border: 1px solid #999999;
  color: #191919;
  margin-left: 1em;
}



button.chat-button span {
  position: absolute;
  bottom: -30px;
  left: calc(50% - 50px);
  font-size: 10px;
  background: #ffc107;
  padding: 6px 14px;
  border-radius: 19px;
  font-weight: bold;
  text-transform: uppercase;
}

.chat-button.with-icon {
    padding: 15px 20px;
    padding-left: 30px;
}

svg.thunder {
  width: 20px;
  position: absolute;
  left: 8px;
}


.chat-button:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.time-icon img {
  width: 50px;
  margin-top: 110px;
  margin-left: 0px;
  margin-bottom: 5px;
  text-align: left;
}

.hours h4 {
  margin-bottom: 10px;
  margin-top: 5px;
}

.sup-msg p {
  width: 300px;
  font-size: 14px;
  font-weight: normal;
  margin-top: 0px
}

.img-icon {
  width: 35px;
  margin-left: 10px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid rgba(9, 30, 66, 0.08);
  transition: ease-in box-shadow .3s;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0), 0 10px 10px -5px rgba(0, 0, 0, 0.0);
}


.img-icon:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.02), 0 10px 10px -5px rgba(0, 0, 0, 0.08);
}

.working-programs {
  margin-bottom: 15px;
}

.icon-grid {
  padding: 0px;
}

.grid-cont {
  padding-left: 0px;
}

.grid-cont h4 {
  padding-top: 0px;
  text-align: left;
  margin-top: 0px;
  margin-bottom: 10px;
}

.map-row {
  padding: 60px 0px;
}

.map img {
  margin-top: 0px !important;
  width: 100%;
  padding-top: 0px;
  margin-bottom: 0px;
}

.cont-col h3 {
  padding-top: 0px;
  text-align: center !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 600;
  text-align: left;
  margin-top: 25px;
  margin-bottom: 10px;
}

.cont-col p {
  text-align: center;
}

.cont-col img {
  margin-bottom: 0px;
  width: 75px;
}

.row.center-xs.cont-row {
  padding: 60px 0px 90px 0px;
}

.cont-row {
  background-color: #f7f7fb;
}

.rating-row {
  padding: 10 px 0px;
  background-color: white;
  margin-top: 0px;

}

.col-lg-3.center-xs.icon-box {
  max-width: 200px;

  margin: 10px;
  border-radius: 5px;
  padding: 23px;

}

img.rate-img {
  margin-top: 0px;
  margin-bottom: 25px;
  width: 75px;
  text-align: left;
}


.col-lg-3.center-xs.icon-box {
  max-width: 200px;
  margin: 10px;
  border-radius: 7px;
  padding: 15px 10px;
  border: 1px solid rgba(9, 30, 66, 0.08);
  transition: ease-in box-shadow .3s;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0), 0 10px 10px -5px rgba(0, 0, 0, 0.0);
}

.col-lg-3.center-xs.icon-box:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.02), 0 10px 10px -5px rgba(0, 0, 0, 0.08);
}

h3.box-title {
  margin-top: 0px;
  margin-bottom: 5px;
}


.footer {
  background: #1f2835;
  color: white;
}

.footer p {
  letter-spacing: 1px;
  font-size: 12px;
  padding : 25px 10px;
  text-align: center;
  line-height: 1;
  margin-bottom : 0;
}


@media screen and (max-width: 990px) {
  .site-logo img {
    margin-left: 0px;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 120px;
  }

  .site-topic h2 {
    font-size: 30px;
    text-align: center;
    font-weight: 900;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .main-bg-img {
    background-image: none !important;
  }

  .nice-select {
    width: 300px;
  }

  .nice-select span {
    font-size: 18px !important;
  }

  .nice-select .list {
    width: 300px;

  }

  .time-icon img {
    width: 50px;
    text-align: left;
    margin: 0px;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .sup-msg p {
    max-width: 200px;
    font-size: 12px;
    font-weight: normal;
    margin-top: 0px;
    color: #768888;
  }

  .main-bg-img {
    padding-bottom: 0px;
  }

  .map-row {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .map {
    padding: 0px;
    padding-bottom: 20px;
  }

  .grid-cont p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .icon-grid .col-xs-12 {
    padding: 0px;
  }

  .img-icon {
    width: 20px;
    margin: 0px;
  }

  .cont-col {
    margin-bottom: 30px;
  }

  .working-programs {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }

  .cont-row {
    padding-bottom: 40px !important;
    padding-top: 40px !important;
  }

  .rating-row {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }


  .col-xs-6.center-xs.icon-box {
    max-width: 150px;
    margin: 10px;
    border-radius: 7px;
    padding: 10px 10px;
    border: 1px solid rgba(9, 30, 66, 0.08);

  }

  .grid-cont h4 {
    font-size: 20px;
  }

  header {
    background-image: none;
  }

  .hero {
    padding-top : 50px;
  }

}

img.rate-img {
  margin-top: 5px;
  margin-bottom: 5px;
  width: 60px;
}
@media only screen and (max-width: 600px) {
.cta-img{
width: 250px;
} 
.site-topic.col-xs-12 h1 {
  font-size: 27px !important;
}
.hero {
  padding-top: 0px;
}
.site-topic p {
  text-align: center;
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 15px;
}
.chat-button {
  margin-top: 35px;
  margin-left: 0px;
  width: 250px;
} 
.review {
  flex-basis: 100%;
    padding: 3px;
        width: 221px !important;
}
button.chat-button.chat-button-outline {
  margin-left: 0px;
  width: 250px;
}
header {
  background-image: none;
  max-height: 139px;
}
section.row.preview-row.py-12 {
  padding: 0px;

}
h2.text-center.mb-6.rate-head.font-semibold.text-3xl {
  font-size: 25px;
}
.chat-button.with-icon {
  padding: 15px 13px;
  padding-left: 26px;
  margin-bottom: 10px;
  margin-top: 15px;
}
}

@media only screen and (max-width: 1024px){

  .col-lg-2.col-xs-10.col-md-5.review {
    margin: 5px;
    padding: 0px;
}
section.row.preview-row.py-12 {
    padding: 0px;
}
header {
  max-height: 500px;
}

.row.start-lg.center-xs {
  text-align: left;
}  
}
